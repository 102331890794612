body {
  font-family: 'Spartan', sans-serif;
  font-size: 12px;
  font-weight: 300;
  color: #000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Linotype';
  src: url('./assets/fonts/Linotype.otf');
}
@font-face {
  font-family: 'UntitledSans';
  src: url('./assets/fonts/UntitledSans.otf');
}
