.status-layout-item-box {
  color: #000000;
}

.status-layout-status-box {
  margin-top: 70px;
  margin-bottom: 75px;
}

.status-layout-item-box.small,
.status-layout-status-box.small {
  font-size: 9pt;
}

/***/

.repair-requests {
  margin-top: 100px;
  .repair-request-status-wrap {
    position: relative;
    padding-left: 90px;
    color: #c4c4c4;
    &:before {
      position: absolute;
      top: 0;
      left: 15px;
      width: 4px;
      height: 100%;
      background-color: #e9e9e9;
      content: '';
    }
    &:after {
      position: absolute;
      top: -9px;
      left: 0;
      width: 34px;
      height: 34px;
      border-radius: 50%;
      background-color: #e9e9e9;
      content: '';
    }
    .title {
      height: 55px;
    }
    .subtitle {
      position: relative;
      height: 55px;
      padding-left: 40px;
      &:before {
        position: absolute;
        top: 7px;
        left: -75px;
        width: 75px;
        height: 4px;
        background-color: #e9e9e9;
        content: '';
      }
      &:after {
        position: absolute;
        top: -1px;
        left: 0;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #e9e9e9;
        content: '';
      }
    }
    .file-wrap {
      margin-top: 30px;
      padding-bottom: 65px;
      color: #000;
      .icon-wrap {
        width: 100px;
        height: 121px;
        background-color: #e5ded5;
        text-align: center;
        .icon {
          line-height: 126px;
          font-size: 40px;
          color: #838383;
        }
      }
    }
  }
  .repair-request-status-wrap.last {
    &:before {
      display: none;
    }
  }
  .repair-request-status-wrap.status-done {
    color: #838383;
    &:before {
      background-color: #c7baa7;
    }
    &:after {
      background-color: #c7baa7;
      text-align: center;
      color: #fff;
      font-size: 16px;
      line-height: 34px;
      content: '';
      font-family: 'Font Awesome 5 Free', sans-serif;
    }
    .subtitle {
      &:before {
        background-color: #c7baa7;
      }
      &:after {
        background-color: #c7baa7;
      }
    }
  }
  .repair-request-status-wrap.status-active {
    color: #838383;
    &:before {
      background-color: #e9e9e9;
    }
    &:after {
      background-color: #c7baa7;
      text-align: center;
      color: #fff;
      font-size: 16px;
      line-height: 34px;
      content: '';
      font-family: 'Font Awesome 5 Free', sans-serif;
    }
    .title {
      &:before {
        position: absolute;
        top: 0;
        left: 15px;
        width: 4px;
        height: 62px;
        background-color: #c7baa7;
        content: '';
      }
    }
    .subtitle {
      &:before {
        background-color: #c7baa7;
      }
      &:after {
        background-color: #c7baa7;
      }
    }
  }
}
