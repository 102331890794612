.price-list-form {
  .form-group {
    label {
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 1.33px;
      font-weight: 600;
    }
    .form-control {
      font-size: 12px;
      font-weight: 400;
      height: 40px;
      border-radius: 0;
      // border: 1px solid rgba(0, 0, 0, 230);
      border: 1px solid #c7baa7;
      outline: none;
      width: 100%;
      &:hover,
      &:active,
      &:focus {
        border: 1px solid darken(#c4c4c4, 15%);
      }
    }
  }
}

input[type='file']::file-selector-button {
  margin: 6px 24px 6px 12px;
  height: 26px;
  background: #70707022 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 8px;
}
