.horizontal-menu {
  margin-bottom: 50px;
  .horizontal-menu-item {
    margin-right: 50px;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    font-size: 11px;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .horizontal-menu-item-active {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}
