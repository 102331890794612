.serial-number-link {
  color: #838383;
  margin-top: 10px;
}
.hidden {
  display: none;
  visibility: hidden;
}

/**/

.repair-request-form {
}
.repair-request-form a,
.repair-request-form a:link,
.repair-request-form a:hover,
.repair-request-form a:active,
.repair-request-form a:visited {
  color: #ccc;
  text-decoration: underline;
}
.repair-request-form input,
.repair-request-form button,
.repair-request-form label,
.repair-request-form select {
  width: 100%;
}

.repair-request-form label.soft {
  font-weight: normal;
  font-size: small;
}

.repair-request-form-question {
  margin-bottom: auto;
  margin-top: auto;
}

.repair-request-form textarea {
  resize: none;
  width: 100%;
  height: 150px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 10px;
}

.repair-request-status-point-l {
  font-size: 35px;
}

.repair-request-status-point-s {
  font-size: 20px;
}

.repair-form {
  .form-group {
    label {
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 1.33px;
      font-weight: 600;
    }
    .form-control,
    .form-select {
      font-size: 12px;
      font-weight: 400;
      height: 40px;
      border-radius: 0;
      // border: 1px solid rgba(0, 0, 0, 230);
      border: 1px solid #c7baa7;
      outline: none;
      width: 100%;
      &:hover,
      &:active,
      &:focus {
        border: 1px solid darken(#c4c4c4, 15%);
      }
    }

    .form-textarea {
      height: auto;
    }
    .form-check-label {
      font-weight: 300;
    }
    .form-check-custom {
      .form-check-label {
        position: relative;
        padding-left: 30px;
        cursor: pointer;
        .form-check-input {
          display: none;
          &:checked ~ .checkmark {
            background-color: #838383 !important;
          }
        }
        &.bold {
          font-weight: 600;
        }
        .checkmark {
          position: absolute;
          top: -2px;
          left: 0;
          height: 20px;
          width: 20px;
          border: 5px solid #e9e9e9;
          background-color: #e9e9e9;
        }
        .form-check-input[type='radio'] ~ .checkmark {
          border-radius: 50%;
        }
        .form-check-input[type='checkbox'] ~ .checkmark {
          border-radius: 50%;
        }
        &:hover .form-check-input ~ .checkmark {
          background-color: darken(#e9e9e9, 5%);
        }
      }
    }
  }
}

input[type='file']::file-selector-button {
  margin: 6px 24px 6px 12px;
  height: 26px;
  background: #70707022 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 8px;
}
