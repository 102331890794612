.app-footer {
  margin: 100px 0 34px;
  padding-top: 38px;
  padding-bottom: 32px;
  border-bottom: 4px solid #f2f2f2;
  border-top: 4px solid #f2f2f2;
  .footer-link {
    font-size: 12px;
    text-decoration: none;
    &.first {
      margin-left: 100px;
    }
    &:hover {
      text-decoration: underline;
    }
  }
  .footer-item-spacer {
    margin-left: 12px;
    margin-right: 12px;
  }
}
